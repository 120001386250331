<template>
	<!-- flex_column -->
	<div class="aircraftBrand_page" style="height: 100%">
		<!-- 飞机库组件 -->
		<hangar :isPhone="isPhone" :buttonList="buttonList" @curBtn="changeIndex" :curBtn="curBtn"
			:title="$t('contactUs.name')"></hangar>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<div class="contactUS_page" style="padding: 20px">
			<div class="center zui-rounded" :class="language == 'en' ? 'en_style1': 'center zui-rounded'">
				<div class="
					zui-flex-row
					zui-justify-center
					zui-align-center
					zui-text-black
					
					zui-font-xl
				" v-if="curBtn !== 5" style="margin-top:20px ; margin-bottom: 30px;">
					{{ $t("contactUs.title") }}
				</div>
				<div class="
					zui-flex-row
					zui-justify-center
					zui-align-center
					zui-text-black
					
					zui-font-xl
				" v-if="curBtn == 5" style="margin-top:20px ; margin-bottom: 30px;">
					{{ $t("contactUs.title2") }}
				</div>
				<!-- <div class="
					zui-flex-row zui-justify-center zui-align-center
					font-color
					font-title
					zui-text-black zui-m-t-10 zui-w-100
				" style="width: 100%; font-size: 18px;text-align: justify;">
					{{ $t("contactUs.desc") }}
				</div> -->
				<!-- 公共字段 -->
				<div class="input" style="padding-bottom: 0">
					<div class="name_input flex_row" v-if="curBtn !== 5">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'">
							<span class="zui-text-danger" :class="language == 'en' ? 'en_style4': ''">*</span><span
								class="center_span" :class="language == 'en' ? 'en_style3': ''">{{ $t("contactUs.planeName")[curBtn] }}</span><span class="maohao">:</span>
						</label>
						<el-input class="zui-flex-1 text-input" v-model="comForm.planeType" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="zui-text-danger" :class="language == 'en' ? 'en_style4': ''">*</span><span
								class="center_span" :class="language == 'en' ? 'en_style3': ''">{{ $t("contactUs.contactName")}}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="comForm.contactName" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="zui-text-danger" :class="language == 'en' ? 'en_style4': ''">*</span><span
								class="center_span" :class="language == 'en' ? 'en_style3': ''">{{ $t("contactUs.contactPhone") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="comForm.contactPhone" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>

					<div class="name_input flex_row">

						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="zui-text-danger" :class="language == 'en' ? 'en_style4': 'zui-text-danger'">*</span><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.email") }}</span><span class="maohao">:</span></label>
						<el-input type="email" class="zui-flex-1 text-input" v-model="comForm.email" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
				</div>
				<!-- 各版块字段 -->
				<div v-if="curBtn <= 2" class="input" style="padding-top: 0; padding-bottom: 0">
					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': ''">{{ $t("contactUs.airRange") }}</span><span class="maohao">:</span></label>
						<el-input type="text" class="zui-flex-1 text-input" v-model="formData.airRange" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>

					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.seatNum") }}</span><span class="maohao">:</span></label>
						<el-input type="number" class="zui-flex-1 text-input" v-model="formData.seatNum" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>

					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.planeAge") }}</span><span class="maohao">:</span></label>
						<el-input type="number" class="zui-flex-1 text-input" v-model="formData.planeAge" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.price") }}</span><span class="maohao">:</span></label>
						<el-input type="text" class="zui-flex-1 text-input" v-model="formData.price" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
				</div>
				<div v-if="curBtn == 3" class="input" style="padding-top: 0; padding-bottom: 0">
					<div class="name_input flex_row">
						<label class="font-color" style="font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label1") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData3.rentManagement" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label2") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData3.modifiyDesign" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label3") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData3.planeCheck" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
				</div>
				<div v-if="curBtn == 4" class="input" style="padding-top: 0; padding-bottom: 0">
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label4") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData4.financeLease" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label5") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData4.operatingLease" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label6") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData4.propertySharing" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label7") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData4.aviationTrust" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label8") }}</span><span class="maohao">:</span></label>
						<el-input class="zui-flex-1 text-input" v-model="formData4.psp" size="mini"
							:placeholder="$t('contactUs.please')"></el-input>
					</div>
				</div>
				<!-- 5 -->
				<div v-if="curBtn == 5" class="input" style="padding-top: 0; padding-bottom: 0">

					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.label10") }}</span><span class="maohao">:</span></label>
						<el-input type="textarea" class="zui-flex-1" v-model="formData5.thought" size="mini"
							:placeholder="curBtn ==2?$t('contactUs.desc11'):$t('contactUs.please')"></el-input>
					</div>
					<div style="height: 10px"></div>

				</div>
				<!-- 公共字段 -->
				<div class="input" v-if="curBtn !== 5" style="padding-top: 0">
					<div class="name_input flex_row">
						<label class="font-color" style=" font-style: normal; font-weight: normal" :class="language == 'en' ? 'en_style2': 'font-color'"><span
								class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.desc1") }}</span><span class="maohao">:</span></label>
						<el-input type="textarea" class="zui-flex-1" v-model="comForm.customerRequire" size="mini"
							:placeholder="curBtn ==2?$t('contactUs.desc11'):$t('contactUs.please')"></el-input>
					</div>
					<div style="height: 10px"></div>
					<div class="name_input zui-flex-row">
						<label class="font-color" style=" 
							font-style: normal;
							font-weight: normal;
							margin-right: 10px;
						" :class="language == 'en' ? 'en_style2': 'font-color'">
						<span class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.planeImg") }}</span>:</label>
						<el-upload ref="upload" :action="$apiUrl + '/sys/common/upload'"
							:data="{ biz: 'temp', isup: 1 }" list-type="fileList" :on-success="uploadFileSuccess"
							:on-remove="handleRemove" :file-list="planeImgList[curBtn]">
							<el-button class="jinBtn" style="border-width: 0" size="mini" type="primary">{{$t('contactUs.upload')}}
							</el-button>
						</el-upload>
					</div>
				</div>
				<div class="input" v-if="curBtn == 5" style="padding-top: 0">
					<div class="name_input zui-flex-row">
						<label class="font-color" style=" 
							font-style: normal;
							font-weight: normal;
							margin-right: 10px;
							" :class="language == 'en' ? 'en_style2': 'font-color'">
							<span class="center_span" :class="language == 'en' ? 'en_style3': 'center_span'">{{ $t("contactUs.uploadResume") }}</span>:</label>
						<el-upload ref="upload" :action="$apiUrl + '/sys/common/upload'"
							:data="{ biz: 'temp', isup: 1 }" list-type="fileList" :on-success="uploadFileSuccess2"
							:on-remove="handleRemove2" :file-list="uploadResumeList[0]" :limit="1">
							<el-button class="jinBtn" style="border-width: 0" size="mini" type="primary">{{$t('contactUs.upload')}}
							</el-button>
						</el-upload>
					</div>
				</div>
			</div>
			<div class="btnBox flex_row">
				<div class="flex_1 flex_row">
					<el-button size="mini" @click="planeMethod(planeMethodName[curBtn])" type="warning" class="login">
						{{ $t("common.submit") }}
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import hangar from "../components/hangarCom";
	import {
		customerService
	} from "../api/api";
	import {
		mapState
	} from "vuex";
	// 飞机品牌页面
	export default {
		data() {
			return {
				isPhone: false,
				buttonList: [{
						name: this.$t("contactUs.buyPlane"),
						id: "0",
					},
					{
						name: this.$t("contactUs.sellPlane"),
						id: "1",
					},
					{
						name: this.$t("contactUs.trusteeshipPlane"),
						id: "2",
					},
					{
						name: this.$t("contactUs.trusteeshipPlane1"),
						id: "3",
					},
					{
						name: this.$t("contactUs.trusteeshipPlane2"),
						id: "4",
					},
					{
						name: this.$t("contactUs.join"),
						id: '5'
					}
				],
				planeMethodName: [
					"airFindInfoAdd",
					"airSellInfoAdd",
					"airPlaneTrusteeshipAdd",
					"airEngineeringService",
					"airAviationFinancial",
					"airJoinUs"
				],
				curBtn: 0,
				comForm: {
					email: "",
					planeImg: "",
					planeType: "",
					customerRequire: "",
					contactName: "",
					contactPhone: "",
				},
				formData: {
					planeName: "",
					registerNum: "",
					airRange: "",
					seatNum: "",
					planeAge: "",
					price: "",
				},
				formData3: {
					modifiyDesign: "",
					planeCheck: "",
					rentManagement: "",
				},
				formData4: {
					aviationTrust: "",
					financeLease: "",
					operatingLease: "",
					propertySharing: "",
					psp: "",
				},
				formData5: {
					thought: "",
				},
				planeImgList: [
					[],
					[],
					[],
					[],
					[]
				],
				uploadResume:'',
				uploadResumeList:[
					[],
				],
				formImgList: ["", "", "", "", ""],
				formImgList2: ["", "", "", "", ""],
				// planeImgList1: [],
				// planeImgList2: [],
				// planeImgList3: [],
				// planeImgList4: [],
				dialogImageUrl: "",
				dialogVisible: false,
			};
		},
		computed: {
			...mapState(["userInfo", "isLogin", "language"]),
		},
		watch: {
			$route(from) {
				// 监听路由变化, 实现类似 小程序的 onShow 事件
				if (from.params.id) {
					this.curBtn = from.params.id - 1;
				} else {
					this.curBtn = 0;
				}
			},
			language() {
				this.$nextTick(() => {
					this.buttonList = [{
							name: this.$t("contactUs.buyPlane"),
							id: "0",
						},
						{
							name: this.$t("contactUs.sellPlane"),
							id: "1",
						},
						{
							name: this.$t("contactUs.trusteeshipPlane"),
							id: "2",
						},
						{
							name: this.$t("contactUs.trusteeshipPlane1"),
							id: "3",
						},
						{
							name: this.$t("contactUs.trusteeshipPlane2"),
							id: "4",
						},
						{
							name: this.$t("contactUs.join"),
							id: "5",
						},
					]
				})
			}
		},
		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			} else {
				this.isPhone = false
			}
			// console.log(this,this.language)
		},
		methods: {
			changeIndex(e) {
				this.curBtn = e;
				// console.log(this.curBtn)
				// this.planeImgList = [];
				// this.resetForm();
				if (this.curBtn == 5) {
					this.comForm = {
						email: "",
						contactName: "",
						contactPhone: "",
						uploadResume:""
					}
				} else {
					this.comForm = {
						email: "",
						planeImg: "",
						planeType: "",
						customerRequire: "",
						contactName: "",
						contactPhone: "",
					}
				}
			},
			resetForm: function() {
				//清空表单

				for (let k in this.comForm) {
					this.comForm[k] = "";
				}

				if (this.curBtn <= 2) {
					for (let k in this.formData) {
						this.formData[k] = "";
					}
				}
				if (this.curBtn == 3) {
					for (let k in this.formData3) {
						this.formData3[k] = "";
					}
				}
				if (this.curBtn == 4) {
					for (let k in this.formData4) {
						this.formData4[k] = "";
					}
				}
				if (this.curBtn == 5) {
					for (let k in this.formData5) {
						this.formData5[k] = "";
					}
				}
				this.planeImgList[this.curBtn] = [];
				this.uploadResumeList[0] = [];
			},
			planeMethod: function(type = "airFindInfoAdd") {
				//判断是否登录
				// if(this.isLogin == false){
				// 	this.$message({
				// 		message: this.$t('pleaseLogin'),
				// 		showClose: true,
				// 		type: 'error',
				//		customClass:'hahaha',
				// 		offset: 20,
				// 		duration: 2000,
				// 		onClose: () => {
				// 			this.$router.push({name:'login'});
				// 		}
				// 	});

				// 	return;
				// }
				var rule;
				// console.log(this.curBtn);
				//定义表单规则
				if (this.curBtn == 5) {
					rule = [{
							name: "contactPhone",
							checkType: "phoneno",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.contactPhone"),
						},
						{
							name: "contactName",
							checkType: "notnull",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.contactName"),
						},
						{
							name: "email",
							checkType: "email",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.email"),
						},
					];
				} else {
					rule = [{
							name: "planeType",
							checkType: "notnull",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.planeType"),
						},
						{
							name: "contactPhone",
							checkType: "phoneno",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.contactPhone"),
						},
						{
							name: "contactName",
							checkType: "notnull",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.contactName"),
						},
						{
							name: "email",
							checkType: "email",
							checkRule: "",
							errorMsg: this.$t("common.formMsg.email"),
						},
					];
				}

				var formData = [];
				if (this.curBtn < 3) {
					formData = Object.assign(this.comForm, this.formData);
				}
				if (this.curBtn == 3) {
					formData = Object.assign(this.comForm, this.formData3);
				}
				if (this.curBtn == 4) {
					formData = Object.assign(this.comForm, this.formData4);

				}
				if (this.curBtn == 5) {
					formData = Object.assign(this.comForm, this.formData5);
					// console.log(formData,type)
				}
				// console.log(formData, rule);
				if (!this.$zuiChecker.check(formData, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: "error",
						customClass: 'hahaha',
						duration: 2000,
						offset: 20,
					});
					return;
				}
				formData.planeImg = this.formImgList[this.curBtn];
				formData.uploadResume = this.formImgList2[0];

				customerService[type](formData).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t("common.submitSuccess"),
							showClose: true,
							type: "success",
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.resetForm();
							},
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: "error",
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
						});
						// console.log(res.message);
					}
				});
			},
			uploadFileSuccess(response, fileList) {
				// console.log(response, fileList);
				if (response.code == 0) {
					this.planeImgList[this.curBtn].push(fileList);
					if (this.planeImgList[this.curBtn].length > 1) {
						let userFile = this.formImgList[this.curBtn].split(",");
						userFile.push(response.message);
						this.formImgList[this.curBtn] = userFile.join(",");
					} else {
						this.formImgList[this.curBtn] = response.message;
					}
				}
			},
			uploadFileSuccess2(response, fileList) {
				// console.log(response, fileList);
				if (response.code == 0) {
					this.uploadResumeList[0].push(fileList);
					
						this.formImgList2[0] = response.message;

				}
			},
			handleRemove(file, fileList) {
				// console.log(file, fileList);
				this.planeImgList[this.curBtn] = fileList;
				let userFile = [];
				this.$zui.each(fileList, function(k, item) {
					userFile.push(item.response.message);
				});
				this.formImgList[this.curBtn] = userFile.join(",");
			},
			handleRemove2(file, fileList) {
				// console.log(file, fileList);
				this.uploadResumeList[this.curBtn] = fileList;
				let userFile = [];
				this.$zui.each(fileList, function(k, item) {
					userFile.push(item.response.message);
				});
				this.formImgList2[this.curBtn] = userFile.join(",");
			},
		},
		components: {
			hangar,
		},
	};
</script>

<style lang="scss" scoped>
	@import "../assets/scss/config.scss";
	@import "../assets/scss/mixin.scss";

	.zui-text-danger {
		position: absolute;
		top: 0;
		left: -0.5pc;
	}

	.center_span {
		width: 7pc;
		text-align-last: justify;

	}


	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		// background: #99a9bf;
	}

	.bg-purple {
		// background: #d3dce6;
	}

	.bg-purple-light {
		// background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	/deep/ .el-upload-list__item-name {
		color: white !important;
	}

	.captcha {
		width: 90px;
		height: 30px;
		margin-left: 20px;
		cursor: pointer;
	}

	.btnBox {
		margin-top: 10px;
		width: 320px;
		margin-bottom: 50px;
	}
	.maohao{
		display: flex;
		align-items: center;
	}
	.login {
		margin-top: 20px;
		background: #dab96b;
		border-color: #dab96b;
		width: 40%;
	}

	.contactUS_page {
		width: 100%;
		height: calc(100% - 130px);
		background-image: url("../assets/image/service_bg.jpg");
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: rgba($color: #000000, $alpha: 1);
		display: flex;
		flex-direction: column;
		align-items: center;
		
	.en_style1{
		width: calc(100vw - 40%) !important;
	}
	.en_style2{
		width: 160px !important;

	}
	.en_style3{
		width: 160px !important;
		text-align-last: left !important;
		// transform: ;
		text-transform: uppercase;
		// text-align: right !important;
	}
	.en_style4{
		left: -6px !important;
	}
		// justify-content: flex-end;s
		.center {
			// height: 600px;
			margin: 20px 0;
			background-color: rgba($color: #000000, $alpha: 0.15);
			width: 50%;
			box-sizing: border-box;
			padding: 10px 10%;

			.text_c {
				font-size: 12px;
				margin-top: 12px;
				line-height: 20px;
			}

			.name_input {
				font-size: 14px;
				font-weight: 400;
				margin-top: 10px;

				.el-input {
					width: 80%;
					margin-left: 10px;
					// border: 1px solid white;
					overflow: hidden;
				}

				.el-textarea {
					margin-left: 10px;
				}
			}

			.textarea {
				margin-top: 10px;
			}

			/deep/ input::-webkit-input-placeholder {
				color: black;
			}

			/deep/ input::-moz-input-placeholder {
				color: black;
			}

			/deep/ textarea::-ms-input-placeholder {
				color: black;
			}

			/deep/ textarea::-webkit-input-placeholder {
				color: white;
			}

			/deep/ textarea::-moz-input-placeholder {
				color: white;
			}

			/deep/ textarea::-ms-input-placeholder {
				color: white;
			}
		}
	}

	.name_input /deep/ .el-input__inner::placeholder {
		color: #000 !important;
		opacity: 0.9;
	}

	.name_input /deep/ .el-textarea__inner::placeholder {
		color: #000 !important;
		opacity: 0.9;
	}

	.name_input label {
		// padding-left: 8px;
		display: inline-block;
		position: relative;
		display: flex;
		justify-content: flex-end;
		// justify-content: space-evenly;
	}

	.zui-text-danger {
		// position: absolute;
		// left: 0;
	}

	.font-color {


		color: black !important;
		width: 7pc;
		display: flex;
		justify-content: space-around;
	}

	/deep/ .el-input__inner {

		background-color: transparent;
		// border-width: inherit;
		border-color: black !important;
		text-align: left;
		color: black;
		//text-transform: uppercase;
	}

	/deep/ .el-input__inner:hover {
		border-color: #dbb96e !important;
	}

	/deep/ .el-textarea__inner {

		border-color: black !important;
		background-color: transparent;
		color: black !important;
		//text-transform: uppercase;
	}




	/deep/ .el-textarea__inner:hover {
		border-color: #dbb96e !important;
	}

	/deep/ .el-upload-list__item:hover {
		background-color: rgba(255, 255, 255, 0.4);
	}
	// 手机适配


	@media screen and (max-width: 960px) {
		.contactUS_page {
			.center {
				padding: 0 10px;
				margin: 0;
				width: 100% !important;

				.input {
					padding: 10px;
				}
			}
			.en_style2{
				width: 110px !important;
			}
			.en_style4{
				left: -6px !important;
			}
		}
		.font-color {
			width: 5pc;
			position: relative;
			// overflow: hidden;
			// margin: ;
		}
		

		.font-title {
			display: inline-block;
			padding: 0 10px;
			// margin: 0 !important;
		}

		.center_span {
			width: 5pc;
			text-align-last: justify;
		}

		.name_input {
			label {
				justify-content: flex-start;
			}
		}
	}

</style>
